*{
  color: red;
  font-family: "Kings", cursive;
}
body{
  background: rgb(39, 39, 39);
}
.App {
  text-align: center;
  background: rgb(39, 39, 39);
}                   

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  height: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);

}

.App-link {
  color: #61dafb;
}

#yum{
  filter: drop-shadow(1px 2px 5px rgb(255, 2, 242));
}
#logoimg {
  height: 10vh;
  filter: drop-shadow(1px 2px 5px rgb(255, 2, 242));
}

.borde{
  border: solid 2px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.589);
  background-color: rgb(10, 10, 10);
}

.firs{
  margin-top: 20px;
}
.container{
  background-color: rgb(39, 39, 39);
  max-width: 60vw;
}
#title{
  white-space: nowrap;
  overflow: hidden;
}

a{
  color:rgb(255, 0, 255) ;
}
p{
  font-size: 20px;
  word-wrap:break-word
}
h1{
  text-shadow: 1px 2px 2px rgb(17, 0, 255);
}
h2{
  text-shadow: 0px 2px 4px rgb(17, 0, 255);
}
.carousel-item img {
  max-height: 100vh;
  object-fit: contain;
  width: 50vw;
}
@media screen and (max-width: 768px) {
  .container {
    width: 95%; /* Full width on smaller screens */
    max-width: none; /* Remove maximum width constraint */
  }
  .carousel-item img {
    width: 95vw;
  }
}